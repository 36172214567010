.table--container{
    display: flex;
    justify-content: center;
    align-items: center;
}

html {
    font-family: sans-serif;
    font-size: 14px;
  }
  
  table {
    border: 1px solid lightgray;
  }
  
  tbody {
    border-bottom: 1px solid lightgray;
  }
  
  th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
  }
  
  tfoot {
    color: gray;
  }
  
  tfoot th {
    font-weight: normal;
  }

  .main--container{
    padding: 20px;
  }
  .padding--10{
    padding: 10px;
  }